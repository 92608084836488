<template>
  <app-list
    :remote="remote"
    :props="props"
    :formProps="formProps"
    :searchQuery="searchQuery"
    :searchControl="searchControl"
    :apiName="apiName"
    :rules="rules"
    :labelWidth="labelWidth"
    :editTitle="'供应商'"
  >
    <div class="search" slot="tool">
      <el-input
        style="width: 280px"
        clearable
        placeholder="根据标题查询"
        v-model="searchQuery.name"
        class="searchInput"
      >
        <template slot="prepend">标题</template>
      </el-input>
    </div>
  </app-list>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/safetyTrace/purchaseProof",
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [0, 0, 0, 0],
        thirdSoltSpan: [19, 19, 19, 19],
      },
      searchVal: "",
      jobList: [],
      searchQuery: { name: "" },
      labelWidth: "120px",
      rules: {
        detectTypeId: [{ required: true, message: "选择检测商品", trigger: "change" }],
        supplierId: [{ required: true, message: "请选择类别供应商", trigger: "change" }],
        proofNo: [{ required: true, message: "请输入凭证编号", trigger: "change" }],
        purchaseBatch: [{ required: true, message: "请输入进货批次", trigger: "change" }],
        purchaseDate: [{ required: true, message: "请选择进货日期", trigger: "change" }],
        merchantPersonnelId: [{ required: true, message: "请选择商户名", trigger: "change" }],
        businessLicensePath: [{ required: true, message: "请上传经营许可证", trigger: "change" }],
        quarantineCertificatePath: [{ required: true, message: "请上传检疫合格证", trigger: "change" }],
        salesReceiptPath: [{ required: true, message: "请上传销售进货单", trigger: "change" }],
        salesInvoicePath: [{ required: true, message: "请上传销售发票", trigger: "change" }],
      },
      props: [
        {
          label: "检测商品类别",
          prop: "detectTypeName",
        },
        {
          label: "商户姓名",
          prop: "merchantPersonnelName",
        },
        {
          label: "凭证编号",
          prop: "proofNo",
        },
        {
          label: "进货批次",
          prop: "purchaseBatch",
        },
        {
          label: "进货日期",
          prop: "purchaseDate",
        },
        {
          label: "供应商",
          prop: "supplierName",
          align: "center",
        },
        {
          label: "供应商联系人",
          prop: "supplierContactPeople",
          align: "center",
        },
        {
          label: "供应商联系电话",
          prop: "supplierContactPhone",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "检测商品类别",
          prop: "detectTypeId",
          type: "select",
          selectData: [],
        },
        {
          label: "供应商",
          prop: "supplierId",
          type: "select",
          selectData: [],
        },
        {
          label: "凭证编号",
          prop: "proofNo",
          type: "input",
        },
        {
          label: "进货批次",
          prop: "purchaseBatch",
          type: "input",
        },
        {
          label: "进货日期",
          prop: "purchaseDate",
          type: "date",
        },
        {
          label: "商户名",
          prop: "merchantPersonnelId",
          type: "select",
          selectData: [],
        },
        {
          label: "经营许可证",
          prop: "businessLicensePath",
          type: "upload",
        },
        {
          label: "检疫合格证",
          prop: "quarantineCertificatePath",
          type: "upload",
        },
        {
          label: "销售进货单",
          prop: "salesReceiptPath",
          type: "upload",
        },
        {
          label: "销售发票",
          prop: "salesInvoicePath",
          type: "upload",
        },
      ],
    };
  },
  async created() {
    await this.getDetectTypes();
    await this.getSuppliers();
    await this.getMerchants();
  },
  methods: {
    async getDetectTypes() {
      const result = await api.common.list({
        apiName: "/system/dataDictionary/getDictionaryParameter",
        type: "检测信息-商品类别",
      });
      this.formProps[0].selectData = result.data;
    },
    async getSuppliers() {
      const result = await api.common.list({
        apiName: "/safetyTrace/supplier/dropDownList",
      });
      const data = result.data.map((item) => {
        return {
          label: item.supplierName,
          value: item.id,
        };
      });
      this.formProps[1].selectData = data;
    },
    async getMerchants() {
      const result = await api.common.list({
        apiName: "/merchant/personnel/dropDownList",
      });
      const data = result.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      this.formProps[5].selectData = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  width: 400px;
}
</style>
